import siteButtonTheme from './siteButtonTheme';
import siteBaseDarkTheme from './siteBaseDarkTheme';

const siteButtonDarkTheme = {
    button: {
        ...siteButtonTheme.button,
        muted: {
            background: siteBaseDarkTheme.base.colors.muted,
            border: siteBaseDarkTheme.base.colors.muted,
        },
        primary: {
            background: siteBaseDarkTheme.base.colors.primary,
            border: siteBaseDarkTheme.base.colors.primary,
            color: siteBaseDarkTheme.base.colors.light,
        },
        info: {
            background: siteBaseDarkTheme.base.colors.info,
            border: siteBaseDarkTheme.base.colors.info,
            color: siteBaseDarkTheme.base.colors.light,
        },
    },
};

export default siteButtonDarkTheme;
