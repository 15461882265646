import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { rem } from 'polished';
import { useFormikContext } from 'formik';
import { animated, useTransition } from 'react-spring';
import { Loader } from '@luigiclaudio/ga-baseline-ui/helpers';
import BrandCardList from './BrandCardList';
import BrandSearchListContainer from './BrandSearchListContainer';
import BrandSearchListOverlay from './BrandSearchListOverlay';
import BrandSearchListContentContainer from './BrandSearchListContentContainer';
import BrandContext from '../../context/BrandContext';
import brandDetailsOnStageInitialState from '../../context/brandDetailsOnStageInitialState';
import BrandSearchListCloseButton from './BrandSearchListCloseButton';
import BrandSearchListCloseIcon from './BrandSearchListCloseIcon';
import brandCardPropTypes from '../../../prop-types/brandCardPropTypes';
import locationPropTypes from '../../../prop-types/locationPropTypes';
import brandUtils from '../../../utils/brandUtils';

const BrandSearchList = ({ list, brandToShow, location }) => {
    const {
        brandOnStage,
        showBrandSearchList,
        setShowBrandSearchList,
        setBrandDetailsOnStage,
        setBrandProductsOnStage,
    } = useContext(BrandContext);
    const theme = useContext(ThemeContext);

    const [showLoader, setShowLoader] = useState(false);
    const [currentList, setCurrentList] = useState([]);
    const [currentSearchTerm, setCurrentSearchTerm] = useState();
    const [moreToShow, setMoreToShow] = useState(brandToShow);
    const [isLoadingBrands, setIsLoadingBrands] = useState(false);
    const [isLoadingBrand, setIsLoadingBrand] = useState(false);

    const { values, resetForm, initialValues } = useFormikContext();
    const { brandFinder } = values;

    useEffect(() => {
        if (location) {
            const { state, pathname } = location;

            if (brandOnStage === state?.brandFinder) {
                setIsLoadingBrand(false);
            }
            if (
                brandOnStage &&
                brandOnStage !== state?.brandFinder &&
                pathname !== `/${brandUtils.getBrandSlug(brandOnStage)}` &&
                state?.brandFinder
            ) {
                setIsLoadingBrand(true);
            }
        }
    }, [location, brandOnStage]);

    useEffect(() => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (typeof window !== 'undefined') {
            const noScrollWindow = () => {
                if (showBrandSearchList) {
                    window.scrollTo(0, scrollTop);
                }
            };

            window.addEventListener('scroll', noScrollWindow, true);

            return () => window.removeEventListener('scroll', noScrollWindow, true);
        }
        return null;
    }, [showBrandSearchList]);

    const transition = useTransition(showBrandSearchList, {
        from: { opacity: 0, y: 200 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: 0 },
    });

    useEffect(() => {
        if (brandFinder) {
            setCurrentSearchTerm(brandFinder.toLowerCase());
        }
    }, [brandFinder]);

    useEffect(() => {
        const filterList = list.filter((listItem) => listItem.tags.includes(currentSearchTerm));
        setCurrentList(filterList);
    }, [currentSearchTerm]);

    useEffect(() => {
        if (!brandFinder) {
            setShowBrandSearchList(false);
        }
        if (brandFinder) {
            setShowBrandSearchList(true);
            setMoreToShow(brandToShow);
        }
    }, [brandFinder]);

    const handleLoadMore = () => {
        setShowLoader(true);
        setMoreToShow((prevState) => prevState + brandToShow);
        setIsLoadingBrands(true);
        setTimeout(() => {
            setShowLoader(false);
            setIsLoadingBrands(false);
        }, 500);
    };

    const handleOnClickCallback = () => {
        setShowBrandSearchList(false);
        setIsLoadingBrand(true);
        setBrandDetailsOnStage({
            ...brandDetailsOnStageInitialState,
        });
        setBrandProductsOnStage(undefined);
    };

    const handleClose = () => {
        setCurrentSearchTerm(brandFinder);
        setShowBrandSearchList(false);
        resetForm(initialValues);
    };

    const AnimatedOverlay = animated(BrandSearchListOverlay);
    const AnimatedContainer = animated(BrandSearchListContainer);

    return (
        <>
            {isLoadingBrand && (
                <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />
            )}
            {transition((style, item) => {
                return (
                    item && (
                        <AnimatedOverlay style={{ opacity: style.opacity }}>
                            <BrandSearchListContentContainer>
                                <AnimatedContainer
                                    style={{
                                        transform: style.y.to(
                                            (value) => `translate3d(0, ${rem(value)}, 0)`,
                                        ),
                                    }}
                                >
                                    <BrandCardList
                                        list={currentList}
                                        brandToShow={moreToShow}
                                        onClickCallback={() => handleOnClickCallback()}
                                        onLoadMore={handleLoadMore}
                                        moreToShow={moreToShow}
                                        listLength={currentList.length}
                                        isLoadingMore={showLoader}
                                        isLoading={isLoadingBrands}
                                    />
                                </AnimatedContainer>
                                <BrandSearchListCloseButton
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    type="button"
                                >
                                    <BrandSearchListCloseIcon />
                                </BrandSearchListCloseButton>
                            </BrandSearchListContentContainer>
                        </AnimatedOverlay>
                    )
                );
            })}
        </>
    );
};

BrandSearchList.propTypes = {
    list: brandCardPropTypes.brandCardList,
    brandToShow: PropTypes.number,
    location: locationPropTypes.location,
};

BrandSearchList.defaultProps = {
    list: undefined,
    brandToShow: 12,
    location: undefined,
};

export default BrandSearchList;
