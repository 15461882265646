import { css } from 'styled-components';
import { darken, rem } from 'polished';

const scrollbarStyles = ({ scrollbarOffset, containerBackgroundColor }) => css`
    &::-webkit-scrollbar {
        width: ${({ theme }) => rem(theme.site.brandSearch.scrollbar.width)};
    }

    &::-webkit-scrollbar-track {
        background-color: ${({ theme }) =>
            darken(0.03, containerBackgroundColor || theme.site.stage.backgroundColor)};
        border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
        margin-bottom: ${({ theme }) =>
            scrollbarOffset || scrollbarOffset === 0
                ? rem(scrollbarOffset)
                : rem(theme.site.brandSearch.scrollbar.offset)};
        margin-top: ${({ theme }) =>
            scrollbarOffset || scrollbarOffset === 0
                ? rem(scrollbarOffset)
                : rem(theme.site.brandSearch.scrollbar.offset)};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.base.grays.grayLighter};
        border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    }
`;

export default scrollbarStyles;
