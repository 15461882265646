import { merge } from 'lodash';
import { baselineTheme } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { authTheme, authDarkTheme } from '@luigiclaudio/ga-baseline-ui/auth';
import { alertTheme, alertDarkTheme } from '@luigiclaudio/ga-baseline-ui/alert';
import { baseTheme, baseDarkTheme } from '@luigiclaudio/ga-baseline-ui/base-theme';
import { buttonTheme, buttonDarkTheme } from '@luigiclaudio/ga-baseline-ui/button';
import { commerceTheme, commerceDarkTheme } from '@luigiclaudio/ga-baseline-ui/commerce';
import { footerTheme, footerDarkTheme } from '@luigiclaudio/ga-baseline-ui/footer';
import { headerTheme, headerDarkTheme } from '@luigiclaudio/ga-baseline-ui/header';
import { typographyTheme, typographyDarkTheme } from '@luigiclaudio/ga-baseline-ui/typography';
import { modalTheme, modalDarkTheme } from '@luigiclaudio/ga-baseline-ui/modal';
import { formTheme, formDarkTheme } from '@luigiclaudio/ga-baseline-ui/form';
import { loaderTheme, loaderDarkTheme } from '@luigiclaudio/ga-baseline-ui/helpers';
import { siteLaunchTheme, siteLaunchDarkTheme } from '@luigiclaudio/ga-baseline-ui/site-launch';
import { layoutTheme } from '@luigiclaudio/ga-baseline-ui/layout';
import {
    horizontalScrollTheme,
    horizontalScrollDarkTheme,
} from '@luigiclaudio/ga-baseline-ui/horizontal-scroll';
import { mailchimpSignUpTheme, mailchimpSignUpDarkTheme } from '@luigiclaudio/ga-mailchimp-signup';
import siteSiteLaunchTheme from './siteSiteLaunchTheme';
import siteSiteLaunchDarkTheme from './siteSiteLaunchDarkTheme';
import siteTheme from './siteTheme';
import siteButtonTheme from './siteButtonTheme';
import siteButtonDarkTheme from './siteButtonDarkTheme';
import siteHeaderTheme from './siteHeaderTheme';
import siteHeaderDarkTheme from './siteHeaderDarkTheme';
import siteLoaderTheme from './siteLoaderTheme';
import siteLoaderDarkTheme from './siteLoaderDarkTheme';
import siteLayoutTheme from './siteLayoutTheme';
import siteTypographyTheme from './siteTypographyTheme';
import siteTypographyDarkTheme from './siteTypographyDarkTheme';
import siteAlertTheme from './siteAlertTheme';
import siteAlertDarkTheme from './siteAlertDarkTheme';
import siteBaseTheme from './siteBaseTheme';
import siteBaseDarkTheme from './siteBaseDarkTheme';
import siteFooterTheme from './siteFooterTheme';
import siteFooterDarkTheme from './siteFooterDarkTheme';
import siteModalTheme from './siteModalTheme';
import siteModalDarkTheme from './siteModalDarkTheme';

const theme = {
    lightMode: merge(baselineTheme.lightMode, {
        alert: merge(alertTheme, { ...siteAlertTheme.alert }),
        base: merge(baseTheme.base, { ...siteBaseTheme.base }),
        button: merge(buttonTheme.button, {
            ...siteButtonTheme.button,
        }),
        commerce: merge(commerceTheme.commerce),
        footer: merge(footerTheme.footer, {
            ...siteFooterTheme.footer,
        }),
        header: merge(headerTheme.header, {
            ...siteHeaderTheme.header,
        }),
        typography: merge(typographyTheme.typography, { ...siteTypographyTheme.typography }),
        mailchimpSignUp: merge(mailchimpSignUpTheme.mailchimpSignUp),
        modal: merge(modalTheme.modal, { ...siteModalTheme.modal }),
        form: merge(formTheme.form),
        auth: merge(authTheme.auth),
        loader: merge(loaderTheme.loader, {
            ...siteLoaderTheme.loader,
        }),
        layout: merge(layoutTheme.layout, {
            ...siteLayoutTheme.layout,
        }),
        siteLaunch: merge(siteLaunchTheme.siteLaunch, {
            ...siteSiteLaunchTheme.siteLaunch,
        }),
        horizontalScroll: merge(horizontalScrollTheme.horizontalScroll),
        ...siteTheme.lightMode,
    }),
    darkMode: merge(baselineTheme.darkMode, {
        alert: merge(alertDarkTheme, { ...siteAlertDarkTheme.alert }),
        base: merge(baseDarkTheme.base, { ...siteBaseDarkTheme.base }),
        button: merge(buttonDarkTheme.button, {
            ...siteButtonDarkTheme.button,
        }),
        commerce: merge(commerceDarkTheme.commerce),
        footer: merge(footerDarkTheme.footer, {
            ...siteFooterDarkTheme.footer,
        }),
        header: merge(headerDarkTheme.header, {
            ...siteHeaderDarkTheme.header,
        }),
        typography: merge(typographyDarkTheme.typography, {
            ...siteTypographyDarkTheme.typography,
        }),
        mailchimpSignUp: merge(mailchimpSignUpDarkTheme.mailchimpSignUp),
        modal: merge(modalDarkTheme.modal, { ...siteModalDarkTheme.modal }),
        form: merge(formDarkTheme.form),
        auth: merge(authDarkTheme.auth),
        loader: merge(loaderDarkTheme.loader, {
            ...siteLoaderDarkTheme.loader,
        }),
        layout: merge(layoutTheme.layout, {
            ...siteLayoutTheme.layout,
        }),
        siteLaunch: merge(siteLaunchDarkTheme.siteLaunch, {
            ...siteSiteLaunchDarkTheme.siteLaunch,
        }),
        horizontalScroll: merge(horizontalScrollDarkTheme.horizontalScroll),
        ...siteTheme.darkMode,
    }),
};

export default theme;
