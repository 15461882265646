import styled from 'styled-components';
import { rem, transparentize } from 'polished';

const BrandSearchListContainer = styled.div`
    height: ${({ theme }) => theme.site.brandSearch.list.height};
    margin: 0 auto;
    max-width: ${({ theme }) => rem(theme.site.brandSearch.list.maxWidth)};
    position: relative;

    &:after,
    &:before {
        content: '';
        height: ${({ theme }) => rem(theme.site.brandSearch.scrollbar.offset)};
        left: 0;
        margin: 0 ${rem(8)};
        pointer-events: none;
        position: absolute;
        width: calc(100% - ${rem(8 * 2)});
        z-index: 1;
    }

    &:after {
        background-image: linear-gradient(
            to bottom,
            ${({ theme }) => transparentize(1, theme.site.stage.backgroundColor)} 10%,
            ${({ theme }) => theme.site.stage.backgroundColor} 90%
        );
        bottom: -2px;
    }

    &:before {
        background-image: linear-gradient(
            to top,
            ${({ theme }) => transparentize(1, theme.site.stage.backgroundColor)} 10%,
            ${({ theme }) => theme.site.stage.backgroundColor} 90%
        );
        top: 0;
    }
`;

export default BrandSearchListContainer;
