import PropTypes from 'prop-types';

const location = PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    href: PropTypes.string,
    origin: PropTypes.string,
    protocol: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    port: PropTypes.string,
    state: PropTypes.shape({}),
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export default { location };
