import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { chunk, isEmpty } from 'lodash';
import { navigate } from 'gatsby';
import CardContainer from './CardContainer';
import BrandCard from '../BrandCard';
import BrandCardListContainer from './BrandCardListContainer';
import LoadMore from './LoadMore';
import NoResults from './NoResults';
import BrandContext from '../../../context/BrandContext';
import brandCardPropTypes from '../../../../prop-types/brandCardPropTypes';
import appConfig from '../../../../data/appConfig.json';

const BrandCardList = ({
    list,
    brandToShow,
    onClickCallback,
    listLength,
    onLoadMore,
    moreToShow,
    showLoader,
    isLoadingBrands,
}) => {
    const { multiCountry, countries } = appConfig;
    const { setBrandOnStage } = useContext(BrandContext);
    const chunkList = chunk(list, brandToShow);

    const { values } = useFormikContext();
    const { brandFinder } = values;
    const brandCardListContainer = useRef();

    useEffect(() => {
        if (brandCardListContainer) {
            brandCardListContainer.current.scrollTo(0, 0);
        }
    }, [brandFinder]);

    const handleOnClick = (to, title) => {
        onClickCallback();
        setBrandOnStage(title);

        // Temp solution, allow the show list to end the animation, then navigate to brand page
        // This needs to be reviewed
        setTimeout(() => {
            navigate(`/${multiCountry ? to : `${to}-${countries[0]}`}`, {
                state: { brandFinder: title },
            });
        }, 500);
    };

    const renderList = !isEmpty(list) ? (
        chunkList[0].map((item) => {
            const { title, totalCount, to } = item;
            return (
                <CardContainer key={to}>
                    <BrandCard
                        title={title}
                        itemsAmount={totalCount}
                        onClick={() => handleOnClick(to, title)}
                    />
                </CardContainer>
            );
        })
    ) : (
        <NoResults />
    );

    return (
        <BrandCardListContainer ref={brandCardListContainer}>
            {renderList}
            <LoadMore
                onLoadMore={onLoadMore}
                currentShown={moreToShow}
                listLength={listLength}
                isLoadingMore={showLoader}
                isLoading={isLoadingBrands}
            />
        </BrandCardListContainer>
    );
};

BrandCardList.propTypes = {
    list: brandCardPropTypes.brandCardList,
    brandToShow: PropTypes.number,
    onClickCallback: PropTypes.func.isRequired,
    listLength: PropTypes.number.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    moreToShow: PropTypes.number.isRequired,
    showLoader: PropTypes.bool,
    isLoadingBrands: PropTypes.bool,
};

BrandCardList.defaultProps = {
    list: undefined,
    brandToShow: 12,
    showLoader: false,
    isLoadingBrands: false,
};

export default BrandCardList;
