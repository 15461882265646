import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@luigiclaudio/ga-baseline-ui/button';
import LoadMoreContainer from './LoadMoreContainer';

const LoadMore = ({ onLoadMore, currentShown, listLength, isLoading, isLoadingMore }) => {
    const handleLoadMore = () => {
        if (currentShown < listLength) {
            onLoadMore();
        }
    };

    const allowLoadMore = !(isLoading || currentShown >= listLength);

    return (
        <>
            {allowLoadMore && (
                <LoadMoreContainer>
                    <Button
                        onClick={handleLoadMore}
                        type="button"
                        skin="muted"
                        btnType="outlined"
                        icon="layers"
                        loading={isLoadingMore}
                    >
                        <FormattedMessage id="brandSearchList.loadMore.label" />
                    </Button>
                </LoadMoreContainer>
            )}
        </>
    );
};

LoadMore.propTypes = {
    onLoadMore: PropTypes.func.isRequired,
    currentShown: PropTypes.number.isRequired,
    listLength: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
};

LoadMore.defaultProps = {
    isLoading: false,
    isLoadingMore: false,
};

export default LoadMore;
