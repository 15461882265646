import siteAlertTheme from './siteAlertTheme';
import siteTheme from './siteTheme';

const siteAlertDarkTheme = {
    alert: {
        ...siteAlertTheme.alert,
        alertFeedback: {
            backgroundColor: siteTheme.darkMode.site.stage.backgroundColor,
        },
    },
};

export default siteAlertDarkTheme;
