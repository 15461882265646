import siteModalTheme from './siteModalTheme';

const siteModalDarkTheme = {
    ...siteModalTheme.modal,
    modal: {
        backgroundColor: '#1c1c1c',
        footer: {
            backgroundColor: '#1c1c1c',
        },
    },
};

export default siteModalDarkTheme;
