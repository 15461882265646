import styled from 'styled-components';
import { rem } from 'polished';

const BrandSearchListCloseButton = styled.button`
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    opacity: 0.7;
    padding: 0;
    position: absolute;
    right: ${rem(32)};
    top: ${rem(32)};
    transition: opacity 0.15s ease-in-out;
    z-index: 2;

    &:hover {
        opacity: 1;
    }

    &:focus {
        outline: none;
    }
`;

export default BrandSearchListCloseButton;
