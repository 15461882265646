import styled from 'styled-components';
import { rem } from 'polished';

const LoadMoreContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: ${rem(16)};
`;

export default LoadMoreContainer;
