import styled from 'styled-components';
import { cssHelpers } from '@luigiclaudio/ga-baseline-ui/helpers';

const BrandTitle = styled.span`
    display: block;
    font-weight: ${({ theme }) => theme.site.brandTitle.fontWeight};
    text-transform: ${({ theme }) =>
        theme.site.brandTitle.uppercase ? 'uppercase' : 'capitalize'};

    ${cssHelpers.textOverflow}
`;

export default BrandTitle;
