const siteHeaderTheme = {
    header: {
        backgroundColor: '#fff',
        shadow: {
            horizontalShadow: '0 4px 8px rgba(0,0,0,0.25)',
        },
        navigation: {
            label: {
                uppercase: true,
                fontWeight: 500,
            },
        },
        menuModal: {
            menuIcon: {
                icon: 'more-horizontal',
            },
        },
        zIndex: 5,
    },
};

export default siteHeaderTheme;
