import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { GaIcon } from '@luigiclaudio/ga-baseline-ui/icon';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { ThemeContext } from 'styled-components';
import BrandTitle from '../../BrandTitle';
import BrandCardAmountsLabel from '../brandSearchListCard/BrandCardAmountsLabel';
import BrandCardAmountsContainer from '../brandSearchListCard/BrandCardAmountsContainer';
import BrandCardButton from './BrandCardButton';
import BrandCardContainer from './BrandCardContainer';
import BrandCardTitle from './BrandCardTitle';
import BrandCardContent from './BrandCardContent';

const BrandCard = ({ title, itemsAmount, onClick }) => {
    const intl = useIntl();
    const theme = useContext(ThemeContext);
    const { themeMode } = useContext(ThemeModeContext);

    return (
        <TransitionItem appear inProp transitionTimeout={{ appear: 50, enter: 100, exit: 100 }}>
            <BrandCardButton onClick={onClick} themeMode={themeMode}>
                <BrandCardContainer>
                    <BrandCardContent>
                        <BrandCardTitle>
                            <BrandTitle>{title}</BrandTitle>
                        </BrandCardTitle>
                        <BrandCardAmountsContainer>
                            <BrandCardAmountsLabel>
                                <FormattedMessage
                                    id="brandCard.itemsAmount"
                                    values={{ amount: intl.formatNumber(itemsAmount) }}
                                />
                            </BrandCardAmountsLabel>
                        </BrandCardAmountsContainer>
                    </BrandCardContent>
                    <GaIcon icon="chevron-right" size={24} color={theme.base.colors.muted} />
                </BrandCardContainer>
            </BrandCardButton>
        </TransitionItem>
    );
};

BrandCard.propTypes = {
    title: PropTypes.string.isRequired,
    itemsAmount: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default BrandCard;
