import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useIntl } from 'react-intl';
import { FormInput } from '@luigiclaudio/ga-baseline-ui/form';
import { SiteContentWrapper, Container, Row, Col } from '@luigiclaudio/ga-baseline-ui/layout';
import BrandSearchContainer from './BrandSearchContainer';
import BrandSearchFieldContainer from './BrandSearchFieldContainer';

const BrandSearch = ({ amount }) => {
    const intl = useIntl();

    return (
        <BrandSearchContainer>
            <SiteContentWrapper>
                <Container>
                    <Row>
                        <Col xxs={30}>
                            <Row justifyContent="center">
                                <BrandSearchFieldContainer>
                                    <Field
                                        component={FormInput}
                                        name="brandFinder"
                                        id="brandFinder"
                                        label={intl.formatMessage(
                                            {
                                                id: 'brandSearch.brandFinder.input.label',
                                            },
                                            {
                                                amount: intl.formatNumber(amount),
                                            },
                                        )}
                                        centered
                                        type="text"
                                    />
                                </BrandSearchFieldContainer>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </SiteContentWrapper>
        </BrandSearchContainer>
    );
};

BrandSearch.propTypes = {
    amount: PropTypes.number.isRequired,
};

export default BrandSearch;
