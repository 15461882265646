// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-brand-rankings-js": () => import("./../../../src/pages/brandRankings.js" /* webpackChunkName: "component---src-pages-brand-rankings-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-confirm-account-js": () => import("./../../../src/pages/confirmAccount.js" /* webpackChunkName: "component---src-pages-confirm-account-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mailing-list-js": () => import("./../../../src/pages/mailingList.js" /* webpackChunkName: "component---src-pages-mailing-list-js" */),
  "component---src-pages-recover-account-js": () => import("./../../../src/pages/recoverAccount.js" /* webpackChunkName: "component---src-pages-recover-account-js" */),
  "component---src-pages-recover-password-js": () => import("./../../../src/pages/recoverPassword.js" /* webpackChunkName: "component---src-pages-recover-password-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/resetPassword.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-show-product-js": () => import("./../../../src/pages/showProduct.js" /* webpackChunkName: "component---src-pages-show-product-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/signIn.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/signUp.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-templates-brand-country-page-template-js": () => import("./../../../src/templates/brandCountryPageTemplate.js" /* webpackChunkName: "component---src-templates-brand-country-page-template-js" */),
  "component---src-templates-category-page-template-js": () => import("./../../../src/templates/categoryPageTemplate.js" /* webpackChunkName: "component---src-templates-category-page-template-js" */)
}

