import siteTypographyTheme from './siteTypographyTheme';
import siteBaseDarkTheme from './siteBaseDarkTheme';

const siteTypographyDarkTheme = {
    typography: {
        ...siteTypographyTheme.typography,
        headings: {
            fontWeight: {
                h1: 700,
                h2: 400,
                h3: 400,
                h4: 400,
                h5: 500,
                h6: 400,
            },
        },
        paragraph: {
            fontWeight: 300,
            lead: {
                fontWeight: 300,
            },
        },
        colors: {
            dark: siteBaseDarkTheme.base.colors.dark,
            muted: siteBaseDarkTheme.base.colors.muted,
        },
        link: {
            color: siteBaseDarkTheme.base.colors.primary,
        },
    },
};

export default siteTypographyDarkTheme;
