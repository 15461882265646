import { validationI18nEnUs } from '@luigiclaudio/ga-baseline-ui/validation';
import { authI18nEnUs } from '@luigiclaudio/ga-baseline-ui/auth';
import { modalI18nEnUs } from '@luigiclaudio/ga-baseline-ui/modal';
import { headerI18nEnUs } from '@luigiclaudio/ga-baseline-ui/header';
import { footerI18nEnUs } from '@luigiclaudio/ga-baseline-ui/footer';
import { commerceI18nEnUs } from '@luigiclaudio/ga-baseline-ui/commerce';
import { buttonI18nEnUs } from '@luigiclaudio/ga-baseline-ui/button';

const baselineUIMessagesEnUs = {
    ...authI18nEnUs,
    ...validationI18nEnUs,
    ...modalI18nEnUs,
    ...headerI18nEnUs,
    ...footerI18nEnUs,
    ...commerceI18nEnUs,
    ...buttonI18nEnUs,
};

export default baselineUIMessagesEnUs;
