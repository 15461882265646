const siteModalTheme = {
    modal: {
        backgroundColor: '#fff',
        footer: {
            backgroundColor: '#fff',
        },
    },
};

export default siteModalTheme;
