import { graphql, useStaticQuery } from 'gatsby';

const useAllBrand = () => {
    const brandList = useStaticQuery(graphql`
        query useAllBrand {
            allBrandTest {
                nodes {
                    brand
                    availableCountries
                    availableGroups
                    groupDetails {
                        group
                        countries
                        retailers
                        retailersByCountries {
                            country
                            retailers
                            totalCount
                        }
                    }
                }
            }
        }
    `);
    return brandList.allBrandTest.nodes;
};

export default useAllBrand;
