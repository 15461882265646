import siteFooterTheme from './siteFooterTheme';

const siteFooterDarkTheme = {
    footer: {
        ...siteFooterTheme.defaultFooter,
        defaultFooter: {
            backgroundStart: '#262626',
            backgroundEnd: '#262626',
            dividerColor: '#3b3b3b',
        },
    },
};

export default siteFooterDarkTheme;
