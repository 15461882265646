import siteSiteLaunchTheme from './siteSiteLaunchTheme';

const siteSiteLaunchDarkTheme = {
    siteLaunch: {
        ...siteSiteLaunchTheme.siteLaunch,
        bgStart: '#262626',
        bgEnd: '#262626',
    },
};

export default siteSiteLaunchDarkTheme;
