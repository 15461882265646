import siteBaseTheme from './siteBaseTheme';

const siteButtonTheme = {
    button: {
        borderRadius: {
            default: 32,
            sm: 16,
            md: 24,
            lg: 32,
        },
        muted: {
            background: siteBaseTheme.base.colors.muted,
            border: siteBaseTheme.base.colors.muted,
        },
        primary: {
            background: siteBaseTheme.base.colors.primary,
            border: siteBaseTheme.base.colors.primary,
            color: siteBaseTheme.base.colors.light,
        },
        info: {
            background: siteBaseTheme.base.colors.info,
            border: siteBaseTheme.base.colors.info,
            color: siteBaseTheme.base.colors.light,
        },
    },
};

export default siteButtonTheme;
