import React from 'react';
import PropTypes from 'prop-types';
import { BaselineThemeProvider } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { MountedProvider } from '@luigiclaudio/ga-baseline-ui/helpers';
import { ModalProvider } from '@luigiclaudio/ga-baseline-ui/modal';
import SiteIntlProvider from './SiteIntlProvider';
import theme from '../styles/theme';
import locationPropTypes from '../prop-types/locationPropTypes';
import SiteHeader from './pageLayout/SiteHeader';
import BrandProvider from './context/BrandProvider';

const Layout = ({ children, location }) => {
    return (
        <SiteIntlProvider>
            <MountedProvider>
                <BaselineThemeProvider theme={theme}>
                    <BrandProvider>
                        <ModalProvider>
                            <SiteHeader location={location}>{children}</SiteHeader>
                        </ModalProvider>
                    </BrandProvider>
                </BaselineThemeProvider>
            </MountedProvider>
        </SiteIntlProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node,
    location: locationPropTypes.location,
};

Layout.defaultProps = {
    location: undefined,
    children: undefined,
};

export default Layout;
