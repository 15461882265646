import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import BrandContext from './BrandContext';
import brandDetailsOnStageInitialState from './brandDetailsOnStageInitialState';

const initialState = {
    brandOnStage: undefined,
    setBrandOnStage: () => {},
    brandProductsOnStage: undefined,
    setBrandProductsOnStage: () => {},
    productTypeOnStage: undefined,
    setProductTypeOnStage: () => {},
    brandDetailsOnStage: brandDetailsOnStageInitialState,
    setBrandDetailsOnStage: () => {},
    searchFilterTypes: undefined,
    setSearchFilterTypes: () => {},
    showBrandSearchList: false,
    setShowBrandSearchList: () => {},
    productFilterList: undefined,
    setProductFilterList: () => {},
    productOnStage: undefined,
    setProductOnStage: () => {},
    productListCurrentRowIndex: undefined,
    setProductListCurrentRowIndex: () => {},
    productTypeListOnSelection: [],
    setProductTypeListOnSelection: () => {},
    catalogFiltersOnStage: undefined,
    setCatalogFiltersOnStage: () => {},
    allProductsUserFromClient: undefined,
    setAllProductsUserFromClient: () => {},
    userFromClient: undefined,
    setUserFromClient: () => {},
};

const actions = {
    SET_BRAND_ON_STAGE: 'SET_BRAND_ON_STAGE',
    SET_BRAND_PRODUCTS_ON_STAGE: 'SET_BRAND_PRODUCTS_ON_STAGE',
    SET_PRODUCT_TYPE_ON_STAGE: 'SET_PRODUCT_TYPE_ON_STAGE',
    SET_BRAND_DETAILS_ON_STAGE: 'SET_BRAND_DETAILS_ON_STAGE',
    SET_PRODUCT_FILTER_TYPES: 'SET_PRODUCT_FILTER_TYPES',
    SET_SHOW_BRAND_SEARCH_LIST: 'SET_SHOW_BRAND_SEARCH_LIST',
    SET_PRODUCT_FILTER_LIST: 'SET_PRODUCT_FILTER_LIST',
    SET_PRODUCT_ON_STAGE: 'SET_PRODUCT_ON_STAGE',
    SET_PRODUCT_LIST_CURRENT_INDEX: 'SET_PRODUCT_LIST_CURRENT_INDEX',
    SET_PRODUCT_TYPE_LIST_ON_SELECTION: 'SET_PRODUCT_TYPE_LIST_ON_SELECTION',
    SET_CATALOG_FILTERS_ON_STAGE: 'SET_CATALOG_FILTERS_ON_STAGE',
    SET_ALL_PRODUCTS_USER_FROM_CLIENT: 'SET_ALL_PRODUCTS_USER_FROM_CLIENT',
    SET_USER_FROM_CLIENT: 'SET_USER_FROM_CLIENT',
};

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_BRAND_ON_STAGE:
            return { ...state, brandOnStage: action.brand };
        case actions.SET_BRAND_PRODUCTS_ON_STAGE:
            return { ...state, brandProductsOnStage: action.brandProducts };
        case actions.SET_PRODUCT_TYPE_ON_STAGE:
            return { ...state, productTypeOnStage: action.productType };
        case actions.SET_BRAND_DETAILS_ON_STAGE:
            return { ...state, brandDetailsOnStage: action.brandDetails };
        case actions.SET_PRODUCT_FILTER_TYPES:
            return { ...state, searchFilterTypes: action.searchFilterTypes };
        case actions.SET_SHOW_BRAND_SEARCH_LIST:
            return {
                ...state,
                showBrandSearchList: action.showBrandSearchList,
            };
        case actions.SET_PRODUCT_FILTER_LIST:
            return { ...state, productFilterList: action.productFilterList };
        case actions.SET_PRODUCT_ON_STAGE:
            return { ...state, productOnStage: action.productOnStage };
        case actions.SET_PRODUCT_LIST_CURRENT_INDEX:
            return { ...state, productListCurrentRowIndex: action.productListCurrentRowIndex };
        case actions.SET_PRODUCT_TYPE_LIST_ON_SELECTION:
            return { ...state, productTypeListOnSelection: action.productTypeListOnSelection };
        case actions.SET_CATALOG_FILTERS_ON_STAGE:
            return { ...state, catalogFiltersOnStage: action.catalogFiltersOnStage };
        case actions.SET_ALL_PRODUCTS_USER_FROM_CLIENT:
            return { ...state, allProductsUserFromClient: action.allProductsUserFromClient };
        case actions.SET_USER_FROM_CLIENT:
            return { ...state, userFromClient: action.userFromClient };
        default:
            return state;
    }
};

const BrandProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        brandOnStage: state.brandOnStage,
        setBrandOnStage: (brand) => {
            dispatch({ type: actions.SET_BRAND_ON_STAGE, brand });
        },
        brandProductsOnStage: state.brandProductsOnStage,
        setBrandProductsOnStage: (brandProducts) => {
            dispatch({ type: actions.SET_BRAND_PRODUCTS_ON_STAGE, brandProducts });
        },
        productTypeOnStage: state.productTypeOnStage,
        setProductTypeOnStage: (productType) => {
            dispatch({ type: actions.SET_PRODUCT_TYPE_ON_STAGE, productType });
        },
        brandDetailsOnStage: state.brandDetailsOnStage,
        setBrandDetailsOnStage: ({ brand, retailer, country, group, products, totalCount }) => {
            dispatch({
                type: actions.SET_BRAND_DETAILS_ON_STAGE,
                brandDetails: { brand, retailer, country, group, products, totalCount },
            });
        },
        searchFilterTypes: state.searchFilterTypes,
        setSearchFilterTypes: (searchFilterTypes) => {
            dispatch({
                type: actions.SET_PRODUCT_FILTER_TYPES,
                searchFilterTypes,
            });
        },
        showBrandSearchList: state.showBrandSearchList,
        setShowBrandSearchList: (showBrandSearchList) => {
            dispatch({ type: actions.SET_SHOW_BRAND_SEARCH_LIST, showBrandSearchList });
        },
        productFilterList: state.productFilterList,
        setProductFilterList: (productFilterList) => {
            dispatch({ type: actions.SET_PRODUCT_FILTER_LIST, productFilterList });
        },
        productOnStage: state.productOnStage,
        setProductOnStage: (productOnStage) => {
            dispatch({ type: actions.SET_PRODUCT_ON_STAGE, productOnStage });
        },
        productListCurrentRowIndex: state.productListCurrentRowIndex,
        setProductListCurrentRowIndex: (productListCurrentRowIndex) => {
            dispatch({ type: actions.SET_PRODUCT_LIST_CURRENT_INDEX, productListCurrentRowIndex });
        },
        productTypeListOnSelection: state.productTypeListOnSelection,
        setProductTypeListOnSelection: (productTypeListOnSelection) => {
            dispatch({
                type: actions.SET_PRODUCT_TYPE_LIST_ON_SELECTION,
                productTypeListOnSelection,
            });
        },
        catalogFiltersOnStage: state.catalogFiltersOnStage,
        setCatalogFiltersOnStage: (catalogFiltersOnStage) => {
            dispatch({ type: actions.SET_CATALOG_FILTERS_ON_STAGE, catalogFiltersOnStage });
        },
        allProductsUserFromClient: state.allProductsUserFromClient,
        setAllProductsUserFromClient: (allProductsUserFromClient) => {
            dispatch({
                type: actions.SET_ALL_PRODUCTS_USER_FROM_CLIENT,
                allProductsUserFromClient,
            });
        },
        userFromClient: state.userFromClient,
        setUserFromClient: (userFromClient) => {
            dispatch({
                type: actions.SET_USER_FROM_CLIENT,
                userFromClient,
            });
        },
    };

    return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>;
};

BrandProvider.propTypes = {
    children: PropTypes.node,
};

BrandProvider.defaultProps = {
    children: undefined,
};

export default BrandProvider;
