const siteFooterTheme = {
    footer: {
        defaultFooter: {
            backgroundStart: '#fcfcfc',
            backgroundEnd: '#fcfcfc',
            dividerColor: '#e2e2e2',
        },
    },
};
export default siteFooterTheme;
