import siteTheme from './siteTheme';

const siteAlertTheme = {
    alert: {
        alertFeedback: {
            backgroundColor: siteTheme.lightMode.site.stage.backgroundColor,
        },
    },
};

export default siteAlertTheme;
