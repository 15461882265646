import styled from 'styled-components';
import { h3Styles } from '@luigiclaudio/ga-baseline-ui/typography';

const BrandCardTitle = styled.span.attrs(() => ({
    colorValue: 'dark',
}))`
    ${h3Styles}
    text-transform: capitalize;
`;

export default BrandCardTitle;
