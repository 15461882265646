import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { GaIcon } from '@luigiclaudio/ga-baseline-ui/icon';

const BrandSearchListCloseIcon = () => {
    const theme = useContext(ThemeContext);

    return (
        <GaIcon
            icon="x"
            color={theme.base.grays.gray}
            size={theme.site.brandDetails.closeIcon.size}
        />
    );
};

export default BrandSearchListCloseIcon;
