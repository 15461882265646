import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { H4 } from '@luigiclaudio/ga-baseline-ui/typography';
import NoResultsContainer from './NoResultsContainer';

const NoResults = () => {
    return (
        <TransitionItem appear inProp transitionTimeout={{ appear: 50, enter: 100, exit: 100 }}>
            <NoResultsContainer>
                <H4 color="muted">
                    <FormattedMessage id="brandSearchList.noResults.title" />
                </H4>
            </NoResultsContainer>
        </TransitionItem>
    );
};

export default NoResults;
