import styled from 'styled-components';
import { rem } from 'polished';

const BrandSearchContainer = styled.div`
    background-color: ${({ theme }) => theme.site.stage.backgroundColor};
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationDefault};
    min-height: ${({ theme }) => rem(theme.site.brandSearch.height)};
    padding: ${({ theme }) => rem(theme.site.brandSearch.spacing)} 0;
    position: fixed;
    top: ${({ theme }) => rem(theme.header.height)};
    width: 100%;
    z-index: 4;
`;

export default BrandSearchContainer;
