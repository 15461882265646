import styled from 'styled-components';
import { rem } from 'polished';

const BrandSearchResultsOffset = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    padding-top: ${({ theme }) => rem(theme.site.brandSearch.height)};
`;

export default BrandSearchResultsOffset;
