import styled from 'styled-components';
import { rem } from 'polished';

const CardContainer = styled.div`
    margin-bottom: ${rem(12)};

    &:last-child {
        margin-bottom: 0;
    }
`;

export default CardContainer;
