const React = require('react');
const { Identity } = require('@luigiclaudio/ga-auth-theme');
const Layout = require('./src/components/layout').default;
const Apollo = require('./src/components/Apollo').default;
require('typeface-heebo');

// eslint-disable-next-line react/prop-types
exports.wrapPageElement = ({ element, props }) => {
    // eslint-disable-next-line react/prop-types
    const { location } = props;
    return (
        <Identity.IdentityContextProvider url={process.env.GATSBY_SITE_BASE_URL}>
            <Apollo>
                <Layout location={location}>{element}</Layout>
            </Apollo>
        </Identity.IdentityContextProvider>
    );
};
