import styled from 'styled-components';
import { rem } from 'polished';

const BrandSearchListOverlay = styled.div`
    background-color: ${({ theme }) => theme.site.stage.backgroundColor};
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: ${({ theme }) => rem(theme.header.height + theme.site.brandSearch.height)};
    width: 100%;
    z-index: 2;
`;

export default BrandSearchListOverlay;
