import styled from 'styled-components';
import { rem } from 'polished';

const BrandSearchLabelContainer = styled.div`
    margin: 0 2vw;
    max-width: ${({ theme }) => rem(theme.site.brandSearch.list.maxWidth)};
    width: 100%;
`;

export default BrandSearchLabelContainer;
