import { useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useUser } from '@luigiclaudio/ga-auth-theme';
import { isEmpty } from 'lodash';
import { GET_USER } from './actionTypes';
import BrandContext from '../components/context/BrandContext';

const useAppUser = () => {
    const client = useApolloClient();
    const { userFromClient, setUserFromClient } = useContext(BrandContext);
    const [user, tokenHasExpired] = useUser();

    const [localUser, setLocalUser] = useState();

    const isPremium = userFromClient?.subscription === 'premium';
    const isFree = userFromClient?.subscription === 'free';
    const subscriptionType = userFromClient?.subscription;
    const groups = userFromClient?.groups;
    const countries = userFromClient?.countries;
    const username = userFromClient?.user_metadata?.full_name;
    const isActive = userFromClient?.activeSubscription;
    const loadingSubscription = userFromClient?.loading;
    const errorSubscription = userFromClient?.error;

    useEffect(() => {
        if (!localUser || tokenHasExpired) {
            setLocalUser(user);
        }
    }, [user, tokenHasExpired]);

    useEffect(() => {
        const run = async () => {
            const { data: queryData, loading } = await client.query({
                query: GET_USER,
                variables: {
                    netlifyId: user?.id,
                },
            });

            setUserFromClient({ ...localUser, ...queryData.findUserByNetlifyID, loading });
        };
        if (localUser) {
            if (!userFromClient) {
                run();
            } else {
                const queryData = client.cache.readQuery({
                    query: GET_USER,
                    variables: {
                        netlifyId: localUser.id,
                    },
                });
                if (queryData) {
                    setUserFromClient({
                        ...localUser,
                        ...queryData.findUserByNetlifyID,
                        loading: false,
                    });
                }
            }
        }
    }, [localUser, tokenHasExpired]);

    useEffect(() => {
        if (userFromClient) {
            if (isEmpty(client.cache.data.data)) {
                client.cache.writeQuery({
                    query: GET_USER,
                    variables: {
                        netlifyId: userFromClient.id,
                    },
                    data: {
                        findUserByNetlifyID: {
                            __typename: 'User',
                            ...userFromClient,
                        },
                    },
                });
            }

            if (!isEmpty(client.cache.data.data)) {
                client.cache.readQuery({
                    query: GET_USER,
                    variables: {
                        netlifyId: userFromClient.id,
                    },
                });
            }
        }
    }, [userFromClient]);

    return {
        isLoadingSubscription: loadingSubscription,
        isErrorSubscription: errorSubscription,
        isPremiumSubscription: isPremium,
        isFreeSubscription: isFree,
        isActiveSubscription: isActive,
        subscriptionType,
        userActiveGroups: groups,
        userActiveCountries: countries,
        username,
    };
};

export default useAppUser;
