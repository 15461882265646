import styled from 'styled-components';
import { rem } from 'polished';
import scrollbarStyles from '../../../../styles/scrollbarStyles';

const BrandCardListContainer = styled.div`
    margin: 0 auto;
    max-height: ${({ theme }) => theme.site.brandSearch.list.height};
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${({ theme }) => rem(theme.site.brandSearch.scrollbar.offset)} ${rem(8)};

    ${scrollbarStyles}
`;

export default BrandCardListContainer;
