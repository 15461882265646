import styled from 'styled-components';
import { rem, lighten, darken } from 'polished';

const BrandCardButton = styled.button`
    background-color: ${({ theme }) => theme.site.brandSearch.card.backgroundColor};
    border: 0;
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationSmall};
    display: block;
    padding: ${rem(16)} ${rem(24)};
    text-align: left;
    transition: background-color 0.15s ease-in-out;
    user-select: none;
    width: 100%;

    &,
    &:focus {
        outline: none;
    }

    &:hover {
        background-color: ${({ theme, themeMode }) =>
            themeMode === 'darkMode'
                ? lighten(0.025, theme.site.brandSearch.card.backgroundColor)
                : darken(0.025, theme.site.brandSearch.card.backgroundColor)};
    }

    &:focus,
    &:active {
        background-color: ${({ theme, themeMode }) =>
            themeMode === 'darkMode'
                ? darken(0.025, theme.site.brandSearch.card.backgroundColor)
                : lighten(0.025, theme.site.brandSearch.card.backgroundColor)};
    }
`;

export default BrandCardButton;
