import { gql } from '@apollo/client';

const GET_USER = gql`
    query GetUser($netlifyId: ID!) {
        findUserByNetlifyID(netlifyId: $netlifyId) {
            id: _id
            emailAddress
            netlifyId
            stripeId
            subscription
            subscriptionPlanId
            activeSubscription
            groups
            countries
        }
    }
`;

const UPDATE_USER = gql`
    mutation UpdateUser(
        $id: ID!
        $netlifyId: ID!
        $stripeId: ID!
        $subscription: String!
        $subscriptionPlanId: String!
        $activeSubscription: Boolean!
        $emailAddress: String!
        $groups: [String]!
        $countries: [String]!
    ) {
        updateUser(
            id: $id
            data: {
                netlifyId: $netlifyId
                stripeId: $stripeId
                subscription: $subscription
                subscriptionPlanId: $subscriptionPlanId
                activeSubscription: $activeSubscription
                emailAddress: $emailAddress
                groups: $groups
                countries: $countries
            }
        ) {
            emailAddress
            netlifyId
            stripeId
            subscription
            subscriptionPlanId
            activeSubscription
            groups
            countries
        }
    }
`;

const CREATE_PRODUCT = gql`
    mutation CreateProduct(
        $brand: String!
        $title: String!
        $url: String!
        $image: String!
        $retailer: String!
        $group: String!
        $price: Float
        $priceBeforeSale: Float!
        $currentLocalPrice: Float!
        $intlKeywords: [String]!
        $currency: String!
    ) {
        createProduct(
            data: {
                brand: $brand
                title: $title
                url: $url
                image: $image
                retailer: $retailer
                group: $group
                price: $price
                priceBeforeSale: $priceBeforeSale
                currentLocalPrice: $currentLocalPrice
                intlKeywords: $intlKeywords
                currency: $currency
            }
        ) {
            brand
            title
            url
            image
            retailer
            group
            price
            priceBeforeSale
            currentLocalPrice
            intlKeywords
            currency
        }
    }
`;

const DELETE_PRODUCT = gql`
    mutation DeleteProductByUrl($url: String!) {
        deleteProductByUrl(url: $url) {
            url
        }
    }
`;

const GET_PRODUCTS = gql`
    query GetAllProducts {
        allProducts {
            data {
                id: _id
                brand
                title
                url
                image
                retailer
                group
                price
                priceBeforeSale
                currentLocalPrice
                intlKeywords
                currency
            }
        }
    }
`;

export { GET_USER, GET_PRODUCTS, UPDATE_USER, CREATE_PRODUCT, DELETE_PRODUCT };
